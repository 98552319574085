@media screen and (max-width: 1279px) {
    body {
        padding-top: 0;
    }

    .container {
        padding-left: 0;
        padding-right: 0;
    }

    header {
        height: auto;
        position: relative;
    }
}
@media screen and (max-width: 1200px){
    .accordion{
        .dropdown-cnt{
            .dropdown-btn{
                flex-direction: column !important;
                align-items: flex-start !important;
                .left{
                    flex-direction: column !important;
                    align-items: flex-start !important;
                    margin-bottom: 8px;
                    .pill{
                        margin-bottom: 8px;
                    }
                }
                .right{
                    position: relative;
                    width: 100%;
                    justify-content: space-between;
                    img{
                        position: absolute;
                        top: -63px;
                        right: 0 !important;
                        margin-left: 0 !important;
                    }
                }
            }
            .active{
                .right{
                    padding-left: 0 !important;
                    span{
                        font-weight: 500 !important;
                    }
                    img{
                        transform: rotate(180deg) !important;
                    }
                }
            }
        }
    }

    .subpage-wrapper{
        .subpage-content{
            .recovery-form-cnt{
                #recoveryForm{
                    .form-row{
                        .form-row-fields{
                            gap: 15px;

                            .fields-column{
                                select{
                                    width: 290px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .show {
        display: block;
    }
    .col-md-8 .content-text {
        padding: 0;
    }
}

@media screen and (min-width: 501px) and (max-width: 930px) {
    .show {
        display: block;
    }
}
@media all and (max-width: 480px) {
    .how-to-apply-video {
        height: 150px;
    }
    .countries-table {
        table {
            border-spacing: 10px;
            tr {
                td {
                    display: inline-block;
                    float: left;
                    width: 50%;
                }
            }
        }
    }
    .page-content {
        .buttons {
            .btn-cta {
                font-size: 13px;
            }
        }
    }
}


@media (max-width: 768px) {
    .expandable-info {
        width: calc(100% - 20px);
        padding: 10px 12px;
    }
    .countries-table {
        padding-top: 0;
    }

    .wholeLogo img {
        max-height: 40px;
    }
}

@media (max-width: 767px) {
    .phone-number.callus-single,
    .phone-number.dropdown {
        width: 16px;
        height: 16px;
    }
}

@media (max-width: 700px) {
    #countriesList {
        column-count: 3;
    }
}

@media (max-width: 512px) {
    #countriesList {
        column-count: 2;
    }
}

@media (max-width: 359px) {
    #countriesList {
        column-count: 1;
    }
}

@media (max-width: 991px) {
    .phone-number.callus-single,
    .phone-number.dropdown {
        margin-left: auto;
        margin-right: 15px;
        display: flex;
        flex-shrink: 0;
    }

    .phone-number.phone-numbers-container {
        display: none;
    }

    .page-wrapper {
        .make-table-on-mobile {
            display: table;
        }
        .make-table-on-mobile-header {
            display: table-header-group;
            text-align: center;
        }
        .make-table-on-mobile-footer {
            padding-top: 10px;
            display: table-footer-group;
        }
    }
    .countries-list {
        column-count: 3;
    }    
}

@media (max-width: 550px) {
    .countries-list {
        column-count: 2;
    }

    .expand-in {}
}

@media (max-width: 360px) {
    #dropdown03 .language {
        display: none;
    }

    .wholeLogo img {
        max-height: 36px;
        margin-left: -8px;
    }
}

@media (min-width: 361px) {
    #dropdown-language.dropdown-menu {
        left: auto;
        right: -16px;
    }
}