main {
  .content {
    color: #00154d;
    position: relative;
    font-family: "FiraSans";
    p {
      margin-bottom: 0.6rem;
      font-weight: 300;
    }
    .front-btn {
      margin-bottom: 20px;
      margin: 0 15px!important;
    }
    .showOnMobile {
      display: none;
    }
    .hideOnMobile {
      display: block;
    }
    h3 {
      font-size: 18px;
      font-weight: 600;
    }
    .no {
      height: 100%;
      color: #a60417;
      font-size: 36px;
      display: inline-block;
      margin-right: 5px;
      font-family: "Lato",Fallback,sans-serif;
      font-weight: 700;
     }
    .ver-align {
      padding-top: 18px;
    }
    .howToStep2 {
      padding-top: 33px;
      padding-bottom: 33px;
      margin: 33px 0px 23px 0px;
      background: #f6f8f9;
    }
  }
  .img-1 {
    padding-top: 12px;
  }
  @media (min-width: 769px) {
  .img-1 {
    padding-top: 45px;
  }
  }
  .content-title {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 10px;

    .row {
      align-items: baseline;
    }
  }
  .content-text {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
  }

  .content-text--findout {
    width: 100%;
    padding-bottom: 32px;
  }
  .countries-table {
    p {
      font-size: 16px;
    }
    ul li {
      font-size: 16px;
    }
  }

  .make-table-on-mobile {
    padding-right: 0px;
  }
  .img-center {
    text-align: center;
  }
}

.countries-table {
  background-color: #f6f8f9;
  margin-top: 12px;
  margin-bottom: 20px;
  padding: 17px 10px 10px;

  .container {
    margin-top: 0;
  }
}

.expand-info-content {
  padding: 0 20px !important;
  font-family: "FiraSans";
  transition: max-height 300ms ease-in-out;

  > div {
    width: 100%;
  }
}

.expandable-header {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 22px;
  margin-top: 20px;
}

.expandable-content {
  margin-bottom: 25px;
  overflow-y: hidden;
  transition: height 300ms ease-in-out;

  &:not(.expanded) {
    border-bottom: 2px solid #00154D;
  }

  &.arrow-expanded .expand-switcher::after {
    transform: rotate(180deg);
  }

  .expand-switcher {
    font-size: 20px;
    position: relative;
    padding-bottom: 5px;
    font-weight: bolder;
    cursor: pointer;
    padding-right: 32px;
    overflow: hidden;

    &:after {
      content: '';
      background: url(../img/arrow_lang.svg);
      background-position: center;
      background-size: auto 10px;
      background-repeat: no-repeat;
      display: block;
      width: 32px;
      height: calc(100% - 5px);
      filter: brightness(0);
      position: absolute;
      right: 0;
      top: 0;
      transition: transform 300ms ease-in-out;
    }
  }

  .expand-content {
    background-color: #fff;
    border: 2px solid #13396A;
    border-radius: 8px;
    padding: 20px;
    font-size: 16px;
    transition: height 300ms ease-in-out;
    -ms-transition: all 300ms;

    ul li {
      font-family: "FiraSans";
      font-weight: 300;
    }
  }
}

@media (max-width: 767.98px) {
  main {
    .content {
      .howToStep2 {
        padding: 10px 0;
        margin: 0!important;
        background: #fff;
      }
      .showOnMobile {
        padding: 20px 0px;
        display: block;
        img {
          height: 500px;
        }
      }
      .hideOnMobile {
        display: none;
      }
      .col-md-4 {
        text-align: center!important;
      }
      .filled{
        .article{
          .make-table-on-mobile {
            display: table;
            padding-left: 15px;
            padding-right: 26px;
            .make-table-on-mobile-footer {
              display: table-footer-group;
              
              .content-text {
                padding: 0;
              }
            }
            .make-table-on-mobile-header {
              display: table-header-group;
            }
          }
        }
      }
      .col-md-12 .content-text {
        padding: 0;
      }
      .content-title {
        font-size: 26px;
        line-height: 38px;
        font-family: "FiraSans", Fallback, sans-serif;
        font-weight: 500;
        padding-top: 22px;
        margin-bottom: 0;
      }
      .container {
        margin-top: 0px;
      }
      .front-btn {
        margin: 25px 10px!important;
      }
    }
  }
}

.expandable-info {
  max-width: 1125px;
  width: calc(100% - 10px);
  margin: 8px auto;
  border: 4px solid rgba(18,51,94,1);
  border-radius: 7px;
  padding: 15px 0;

  &__header {
    font-size: 16px;
    text-align: center;
    display: block;
    padding: 0;
    font-family: "FiraSans";
    font-size: 18px;

    a, a:hover, a:visited {
      color: #5981C9;
      text-decoration: none;
      line-height: 22px;
    }
  }

  &__description {
    overflow: hidden;
    height: 0;
    line-height: 20px;

    &[aria-hidden="false"] {
      margin-top: 20px;
      height: auto;

      + button {
        transform: rotate(180deg);
      }
    }
  }
  
  button {
    background-color: inherit;
    border: none;
    transition-duration: 400ms;
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }
}

.expand-info-btn {
  position: absolute;
  right: 10px;
  top: 25px;
  background-color: rgba(0,0,0,0);
  border: none;
  outline: none;
}

.main-background {
  height: 500px;
  width: 100%;
  position: absolute;
  z-index: 0;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.text-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.accordion{
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  .dropdown-cnt{
    display: flex;
    flex-direction: column;
    border: 1px solid #05193E;
    border-bottom: 0;
    &:last-child{
      border-bottom: 1px solid #05193E;
    }
    .dropdown-btn{
      all: unset;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      padding-right: 28px;
      .left, .right{
        display: flex;
        align-items: center;
        color: #05193E;
        img{
          margin-left: 24px;
        }
      }
      .left{
        span{
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
        }
      }
      .right{
        span{
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
        }
      }
      .pill{
        display: inline-flex;
        align-items: center;
        padding-left: 5px;
        padding-right: 8px;
        border-radius: 30px;
        font-weight: 700;
        font-size: 18px;
        line-height: 34px;
        margin-right: 8px;
        img{
          margin-right: 5px;
          margin-left: 0;
        }
      }
      .pill-blue{
        background: #05193E;
        border: 2px solid #05193E;
        color: #fff;
      }
      .pill-white{
        border: 2px solid #05193E;
        border-radius: 30px;
        font-weight: 600;
        font-size: 18px;
        line-height: 34px;
      }
    }
    .collapse{
      padding: 0 64px 20px 20px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #05193E;
      a{
        font-weight: 600;
      }
    }
    .hide{
      display: none;
    }
    .active{
      justify-content: initial;
      padding-bottom: 15px;
      .left, .right{
        span{
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
        }
      }
      .desktop-hidden{
        font-weight: 500 !important;
      }
      .right{
        padding-left: 5px;
        img{
          position: absolute;
          right: 24px;
          transform: rotate(180deg);
        }
      }
    }
  }
  .accordion-separator{
    width: 100%;
    height: 10px;
    background: #05193E;
  }
}

.apply-arrow {
  position: absolute;
  left: 390px;
  top: 95px;
  @media (max-width: 991px) {
    left: 370px;
    top: 70px;
  }
  @media (max-width: 767px) {
    transform: rotate(90deg);
    left: 100px;
    top: 150px;

    img {
      height: 82px;
    }
  }
  @media (max-width: 420px) {
    top: 180px
  }
}

@media (min-width: 769px) {
.margin-top-row {
  margin-top: 33px;
}
}

.content-button-container .front-btn {
  width: calc(100% - 30px);
}

@media (max-width: 767px) {
  .mobile-no-margin-top {
    margin-top: 0 !important;

    *:first-child {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
  }
}

.mr-0 {
  margin-right: 0;
}

[aria-hidden=true] {
  display: none;
}


@media (max-width: 991px) {
  #CountriesTable {
    tr {
      display: flex;
      flex-wrap: wrap;
      td {
        width: 50%;
        display: block;
      }
    }
  }
}

@media (max-width: 500px) {
  #CountriesTable {
    tr {
      td {
        width: 100%;
      }
    }
  }
}