footer {
  margin-top: 30px;
  height: auto;
  background: #010b23;
  color: #fafbfc;
  padding-top: 70px;
  font-family: "Lato", Fallback, sans-serif;
  .btn-cta {
    font-size: 14px;
    font-weight: 700;
    color: #fafbfd;
  }
  ul {
    margin: 0;
    padding: 0;
    li:nth-child(1){
      border-top: 1px solid rgba(255,255,255,.125);
    }
    li {
      border-bottom: 1px solid rgba(255,255,255,.125);
      list-style: none;
      padding: 10px 0px;
      width: 80%;
      &:hover {
        text-decoration: underline;
      }
      a {
        font-size: 16px;
        color: #f0f2f4;
        &:after, &:hover, &:active, &:focus, &:visited {
          color: #f0f2f4;
        }
      }
    }
  }
  .box1, .box2 {
    padding-left: 25px;
  }
  h4 {
    letter-spacing: 1px;
    font-size: 18px;
    text-transform: uppercase;
    font-family: "FiraSans", Fallback, sans-serif;
    font-weight: 600;
    padding-bottom: 30px;
  }

  .notice {
    background: #151e34;
    font-size: 12px!important;
    word-wrap: break-word;
    color: #8e919a;
    margin-top: 60px;
    padding: 30px 10px 30px 10px;
    p{
      font-size: 12px!important;
    }
  }
}

// Small devices (landscape phones, less than 767px)
@media (max-width: 767.98px) {
  footer {
    li {
      width: 80%!important;
    }
    .box1 {
      margin-top: 15px;
    }
    .box1, .box2 {
      padding: 15px;
    }
    .btn-primary{
      margin-top: -50px;
    }
    .notice{
      padding: 0px 10px 0px 10px!important;
      height: auto;
    padding-top: 10px!important;
    }
    .notice p {
      margin-top: 12px;
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  footer {
    .btn-primary {
      width: 100%;
    }
    li {
      width: 100%!important;
    }
  }
}
