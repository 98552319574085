@import "variables";
@import "general";
@import "oldCss";
@import "fonts";
@import "footer";
@import "sprites";
@import "bootstrap";
@import "header";
@import "homepageBackground";
@import "homepageContent";
@import "check-status";
@import "mainRwd";

.form-heading {
	text-align: center;
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 30px;

	@media (min-width: 768px) {
		margin-top: 60px;
	}
}

body {
	.ui-tooltip {
		@media (max-width: 600px) {
			max-width: 300px;
		}
		max-width: 500px;
		-webkit-box-shadow: none;
		box-shadow: none;
		border-color: #335075;
		border-width: 1px;
		.ui-tooltip-content {
			font-size: 12px;
		}

		.express-processing-tooltip {
			max-width: 296px;
			line-height: 18px;
		}

		@media (max-width: 600px) {
			left: 40px !important;
			right: 40px !important;

			.express-processing-tooltip {
				max-width: initial;
			}
		}
	}
	.page-payment-with-redirect {
		padding-top: 10vh;
		margin: 0;
		background-color: transparent;
		.page-wrapper {
			height: 80vh;
			min-height: 150px;
			margin: 0;
			padding: 0;
			position: relative;
			border: 1px solid rgba(112,112,112,.11);
			border-right: none;
			border-radius: 5px;
			background-color: #fff;
		}
		.animation-progress {
			margin-left: auto;
			margin-right: auto;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 100%;
			position: absolute;
			left: 0;
			right: 0;
			&.animation-progress-evisa-express {
				width: 140px;
				height: 140px;
				top: calc(50% - 70px);
				background-image: url('/assets/img/email/evisa-logo-small.png');
				animation: loadingPayment 3s infinite;
				
			}
		}
	}

	@keyframes loadingPayment {
		0% {
			transform: scale(1) rotate(0deg);
		}
		25% {
			opacity: 0.5;
			transform: scale(0.7) rotate(0deg);
		}
		50% {
			opacity: 1;
			transform: scale(1) rotate(0deg);
		}
		100% {
			transform: scale(1) rotate(360deg);
		}
	}
}

.main-text {
	.form-application-content-no {
		text-align: center;
		font-weight: bold;
	}
}

.form {
	border: 1px solid rgba(0, 22, 77, 0.1);
	border-radius: 8px;
	display: flex;

	#recoveryForm, #form {
		width: 100%;
	}

	.form-row-heading {
		background-color: #00164D14;
		min-height: 48px;
		height: 50px;
		border-radius: 8px 8px 0 0;
		color: #00154d;
		padding: 0 30px;
		margin-bottom: 30px;
		font-weight: 700;
		text-transform: uppercase;
		display: flex;
    	align-items: center;
	}

	.no-hint {
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.form-row.label-flex-wrap label {
		flex-wrap: wrap;
	}

	.form-row-fields {
		display: flex;
		flex-wrap: wrap;
		padding-right: 30px;
		position: relative;
		.fields-column {
			padding-left: 30px;
			margin-bottom: 30px;
			label {
				font-weight: 600;
				display: inline-flex;

				.sub-label {
					margin-top: 4px;
					display: flex;
					width: 100%;
					font-weight: 400;
					color: #00154D;
				}
			}
			input:not([type="checkbox"]):not([type="radio"]), select, textarea {
				margin-top: 15px;
				border-radius: 8px;
				line-height: 20px;
				height: 48px;
				padding: 0 15px;
				border: 1px solid rgba(0, 22, 77, 0.3);
				outline: none;

				&:focus {
					border: 1px solid #00164D;
					color: #00164D;
					box-shadow: 0px 0px 10px rgba(#00164D, 0.2);
				}
				&.with-success {
					border: 1px solid #00B352;
					color: #00B252;
					box-shadow: 0px 0px 10px rgba(#00B252, 0.2);
				}
				&.with-error {
					border: 1px solid #E60000;
					color: #E50000;
					box-shadow: 0px 0px 10px rgba(#E50000, 0.2);
				}
			}
			textarea {
				padding: 10px;
			}
			select, input {
				background-color: #fff;
			}
		}

		.employment-position-wrapper {
			position: absolute;
			padding: 0 20px 0 20px;
			transform: translateY(88px);
			z-index: 1000;
			left: 33%;
			width: 33%;
			.employment-position-list {
				list-style-type: none;
				max-height: 560px;
				min-height: 29px;
				overflow-y: scroll;
				background: #fff;
				margin: 0;
				border: 1px solid #717E77;
				border-radius: 4px;
				padding: 0;
				padding-top: 25px;

				li {
					color: rgb(109, 109, 109);
					padding: 6px 16px;
					border: 0.5px solid #ebebe4;

					&:not(.no-results):hover {
						background-color: #1073db;
						color: #fff;
						cursor: pointer;
					}

					&:not(.no-results)[disabled] {
						color: #fff;
						background-color: rgb(235,235,228);
						position: absolute;
						top: 1px;
						width: calc(100% - 43px);
						border-radius: 4px 4px 4px 4px;
						padding-top: 0;
						padding-bottom: 0;
					}
				}
			}
		}

		.insurance-question {
			margin-bottom: 0;
		}

		.AdditionalBrazilQuestions {
			display: flex;
			flex-direction: column;

			label {
				margin-bottom: 5px;
			}
		}
	}

	.form-row-fields .fields-column:not(.radio-column) {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.info-container {
		display: flex;
		align-items: flex-start;
		border: 1px solid #7C152A;
		margin: -10px 30px 20px;
		padding: 16px;
		border-radius: 6px;

		img {
			margin-right: 8px;
			margin-top: 6px;
		}

		.info-container-content {
			color: #7C152A;
			font-weight: 500;
			width: calc(100% - 40px);

			.link {
				color: #0042E5;
			}
		}
	}

	input:not([type="radio"]):not([type="checkbox"]),
	select,
	textarea {
		width: 100%;
		max-width: 100%;
		max-height: 500px;
	}

	.dateTitle {
		margin: 0 30px;
		font-weight: 700;
	}

	.btn-wrapper {
		padding: 0 30px;
		.submit-btn {
			background-color: #0042E5;
			width: 100%;

			&.btn-gray {
				background-color: #B3B9CA;
			}
		}
	}
	.radio-column {
		.radio-wrapper {
			min-width: 24px;
			width: 24px;
			height: 24px;
			border: 1px solid rgba(0, 22, 77, 0.3);
			border-radius: 4px;
			display: inline-block;
			margin-right: 5px;
			position: relative;
			.radio-checked {
				width: 16px;
				height: 16px;
				position: absolute;
				top: 3px;
				left: 3px;
				display: block;
				transition: all .25s ease;
			}
		}

		.with-error span, .with-error div {
			color: #E50000;
		}

		.radio-group-label {
			margin-bottom: 15px;
		}
	
		.radio-wrapper [type="checkbox"]:checked+.radio-checked:after,
		.radio-wrapper [type="radio"]:checked+.radio-checked:after {
			display: block;
			content: "";
			width: 16px;
			height: 16px;
			background: url("../img/form-tick.svg");
			background-size: 100% 100%;
		}
	}

	.radio-wrapper [type="checkbox"],
		.radio-wrapper [type="radio"] {
			opacity: 0;
		}

	.radio-column:not(.terms-long) label {
		margin-right: 72px;
	}

	.no-heading-radio {
		.radio-group-label {
			font-weight: 600;
			margin-bottom: 15px;
		}
		label {
			font-weight: 400;
		}
	}

	.terms-long {
		font-size: 14px;
		label {
			display: flex;
		}
	}

	.terms-additional-description {
		margin-bottom: 10px;
		padding: 0 30px;
		.accept-terms-long {
			overflow: hidden;
			height: 160px;
			font-size: 12px;
			color:rgba(0, 22, 77, .4);
			&.terms-language-en {
				height: 140px;
			}
			h4 {
				margin-top: 0;
				margin-bottom: 5px;
			}
			&.full-text {
				height: auto;
			}
		}
		.terms-read-more {
			position: relative;
			text-align: center;
			cursor: pointer;
			span {
				position: relative;
				z-index: 2;
				background: #FFF;
				padding: 0 5px;
			}
			&:after {
				position: absolute;
				top: 50%;
				left: 0;
				display: block;
				content: "";
				width: 100%;
				height: 1px;
				background: #335075;
			}
		}
	}

	.citizenship-btn {
		width: 5%;
		min-width: 50px;
		margin-left: 30px;
	}

	.btn-add {
		background-color: #0042E5;
	}

	.btn-remove {
		background-color: #ED2939;
	}

	.hint-icon {
		background: url("../img/hint-icon-2.svg");
		width: 16px;
		background-size: 16px 16px;
		background-repeat: no-repeat;
		display: inline-block;
		height: 16px;
		position: relative;
		top: 1px;
		margin: 0 5px;
		min-width: 16px;
	}
	
	.form-row-heading .hint-icon {
		background: url("../img/hint-icon.svg");
		background-repeat: no-repeat;
	}

	.column-100 {
		width: 100%;
	}
	
	.column-50 {
		width: 50%;
	}

	.column-33 {
		width: 33%;
	}

	.column-25 {
		width: 25%;
	}

	.column-16-5 {
		width: 16.666667%;
	}

	.margin-top-15 {
		margin-top: 15px;
	}
}

.submit-btn {
	margin: 20px 0px 40px 0px;
	border-radius: 8px;
	border: none;
	font-size: 16px;
	font-weight: 700;
	color: #fff;
	padding: 12px 15px;
	box-sizing: border-box;
	background: #0042E5;

	&.submit-btn-link {
		padding: 22px 30px;
		margin-top: 26px;
		margin-bottom: 0;
		display: inline-block;
	}
}

.btn-form-container {
	.submit-btn {
		margin: 15px 15px;
	}
	display: flex;
	justify-content: center;
	align-items: center;
}

.loading {
	position: relative !important;
	color: transparent !important;
	text-shadow: none !important;
	&:after {
		position: absolute;
		top: calc(50% - 16px);
		left: calc(50% - 16px);
		content: "";
		display: block;
		background: url("../img/loader.svg");
		background-size: 100%;
		width: 32px;
		height: 32px;
		border: 0 !important;
		animation-name: loading;
		animation-duration: 0.75s;
		animation-iteration-count: infinite;
		animation-delay: 0s;
		animation-timing-function: linear;
	}
}

@keyframes loading {
    0% {
        transform: rotateZ(0deg);
    }

    100% {
        transform: rotateZ(360deg);
    }
}
.pay-btn {
	img {
		margin-left: 10px;
    }
    .paypal, .sofort {
        height: 27px;
    }
}

.fast-track-cnt {
	width: 100%;
	padding: 10px 0 20px 30px;
	display: flex;
	flex-direction: column;

	.fast-track-text {
		font-weight: 700;
	}

	.fast-track-additional-description {
		display: flex;
		justify-content: space-between;

		.fast-track-price {
			font-weight: 600;
			color: #0042E5;
		}
	}

	.lists-wrapper {
		display: flex;
		margin: 20px 0;
		gap: 40px;
		line-height: 24px;

		@media (max-width: 799px) {
			flex-direction: column;
			gap: 20px;
		}

		.list-header {
			font-weight: 700;
		}

		ul {
			list-style-type: none;
			padding-left: 0;
		}

		li {
			padding-left: 23px;
		}

		.benefits-list {
			li {
				background: url('/assets/img/icons/icon-shield-check.svg') left top 2px no-repeat;
				background-size: 17px;
			}
		}

		.best-for-list {
			li {
				background: url('/assets/img/icons/icon-circle-check.svg') left top 2px no-repeat;
				background-size: 17px;
			}
		}
	}

	.hidden {
		display: none;
	}

	.comfort-pass {
		margin-top: 15px;
	}
}

@import "formRwd";
@import "insurance";