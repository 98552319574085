@media screen and (min-width: 971px) and (max-width: 991px) {
    #card-processing-form {
        #card-holder-second-name-container {
            width: 45%;
        }

        #card-expiration-month-container {
            width: 13%;
        }

        #card-expiration-year-container {
            width: 15%;
        }

        #card-cvv-code-container {
            width: 27%;
        }
    }
}

@media screen and (min-width: 971px) {
    #card-processing-form {
        div.form-row:nth-of-type(3) {
            .fields-column {

                input,
                select {
                    margin-top: 30px;
                }
            }
        }
    }
}

@media screen and (max-width: 970px) {
    .cvv-information {
        bottom: 195px;
    }

    .page-payment {

        .process-steps {
            display: none;
        }

        #payment-form {
            .div-input {
                font-size: 14px;
                padding: 10px;
                height: auto;
            }

            .form-row {
                margin-bottom: 0;

                .form-row-fields {
                    .fields-column {
                        width: 100%;

                        .label-hidden {
                            display: block;
                        }
                    }
                }

                .fields-column {
                    &.spacer {
                        display: none;
                    }
                }

                &.order-row {
                    margin-bottom: 10px;
                }

                &:nth-last-of-type(2) {
                    margin-bottom: 15px;
                }
            }

            .label-placehoder {
                height: auto;
                margin-top: 10px;
            }
        }
    }

}

@media screen and (max-width: 991px) {
    body {
        .form {
            .form-row {
                margin: 0;
                padding-top: 0;

                .form-row-fields {
                    margin: 0;

                    .fields-column {
                        &.column-50 {
                            width: 100%;
                        }
                        &.column-25 {
                            width: 50%;
                        }
                        &.column-16-5 {
                            width: 33.3333333%;
                        }
                    }

                    .insurance-question {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 799px) {
    .cvv-information {
        right: 15px;
    }

    .form-row {
        &.payment-type {
            label {
                img {
                    vertical-align: middle;
                    margin-top: 6px;
                }
            }
        }
    }

    .form {
        .form-row {
            .form-row-fields {
                margin: 0;

                .fields-column {
                    &.column-15 {
                        width: 25%;
                    }

                    &.column-20 {
                        width: 30%;
                    }
                }
            }
        }
    }

    .page {
        &.page-payment-success {
            .page-wrapper {
                padding-top: 70px;
            }

            .payment-info {
                font-size: 18px;
            }
        }
    }
}

@media (max-width: 768px) {
    .page {
        .page-wrapper {
            padding-right: 0;
            padding-left: 0;

            .form {
                .form-row-heading {
                    border-radius: 0;
                    padding: 14px;
                    height: auto;

                    span {
                        width: 40px;
                    }
                }

                .radio-column:not(.terms-long) label {
                    margin-right: 50px;
                }
            }
        }
    }
}

@media screen and (max-width: 619px) {
    body {
        .form {
            .form-row {
                .form-row-fields {
                    .fields-column {
                        &.column-25 {
                            width: 100%;
                        }
                        &.column-16-5 {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .cvv-information {
        right: 5px;
        bottom: 230px;
    }

    .form {
        .form-row {
            .form-row-fields {
                margin: 0;

                .fields-column {
                    &.column-15 {
                        width: 33.3333%;
                    }

                    &.column-20 {
                        width: 33.3333%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .form {
        .form-row {
            .form-row-fields {
                margin: 0;
            }
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
    .phone-number.phone-numbers-container {
        display: none;
    }

    .payment-successful {
        div:first-child {
            table {
                display: table;

                .thead-blue {
                    display: none;
                }

                tbody {
                    display: table-row-group;

                    tr {
                        display: table-row;
                    }
                }
            }
        }
    }
    .all-payments-container  {
        flex-direction: column;
        .btn {
            width: 100%;
            &.btn-payment-type {
                min-height: 50px;
                padding: 0;
                margin: 5px 0;
            }
        }
    }
}

@media (max-width: 767.98px) {
    .payment-successful {
        .offset-1 {
            margin: 0 !important;
        }

        .payment-info {
            img {
                height: 60px !important;
            }

            .payment-title {
                height: auto !important;
                line-height: 35px !important;
                margin-bottom: 15px !important;
            }

            .payment-text {
                margin-top: 10px;
            }

            .table {
                width: 80% !important;
                margin: 20px 10% !important;
            }
        }

        .notice {
            margin: 0 !important;
            padding: 0px 10px 20px 10px !important;
        }

        .payment-bg {
            height: 900px;
        }
    }

    body {
        .form {
            .form-row {
                .form-row-fields {
                    .fields-column {
                        &.column-33 {
                            width: 100%;

                            &.employment-position-wrapper {
                                width: calc(100% - 19px);
                                left: 9px;
                                transform: translateY(207px);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 576px) {
    body {
        .form {
            .form-row {
                .form-row-fields {
                    .fields-column {
                        &.column-33 {
                            &.employment-position-wrapper {
                                .employment-position-list {
                                    position: relative;
                                    li[disabled] {
                                        width: 100%;
                                        top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .payment-successful {
        .offset-1 {
            margin: 0 !important;
        }

        .col-md-10 {
            padding: 0 !important;
        }

        .payment-title {
            font-size: 35px !important;
        }

        .payment-text {
            padding: 0 15px !important;
        }

        .front-btn {
            width: 80% !important;
        }
    }
}

@media screen and (max-width: 1199px) {
    .all-payments-container {
        .btn {
            &:not(.info):before {
                content: none;
            }
            &.btn-payment-type {
                padding: 0;
            }
        }
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 992px) {
    body {
        .container {
            max-width: 1140px;
        }

        .form-row {
            &.column-50 {
                float: left;
                width: 50%;
            }

            .form-row-fields {
                .fields-column {
                    &.column-16-5 {
                        width: 16.666667%;
                    }
                }
            }
        }
    }
}