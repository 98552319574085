header {
  background: #fafbfd;
  position: relative;
  top: 0;
  right: 0;
  z-index: 1200;
  left: 0;
  font-family: "Lato";

  .navbar {
    font-weight: 900;
    height: 60px;
    margin-bottom: 0;
    background-color: #fff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    padding: 10px;
    .container {
      overflow: visible;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
    }
    .navbar-nav {
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      .nav-link {
        padding-left: 0px;
        padding-right: 0px;
        display: flex;
      }
    }
  }
}
.navbar-nav .dropdown-menu {
  position: absolute;
  margin-right: -10px;
}

.text-heading-mobile {
  display: none;
}

.language-switcher {
  margin-right: 30px;

  a {
    color: #00164d;
    font-weight: 800;
    font-size: 15px;
    position: relative;
    cursor: pointer;
    margin-right: 18px;
  }

  img {
    margin: 0 8px 0 0;
  }
}

.phone-loading-hidden {
    display: none !important;
}

#dropdown04::after {
  left: 30px;
  top: 10px;
}
#dropdown-phone.dropdown-menu {
  min-width: 6rem;
  background-color: #fff;
  .dropdown-item {
    display: block;
    padding: 10px 20px;
    height: 50px;
  }
}

#dropdown-language.dropdown-menu {
  min-width: 8rem;
  left: -72px;
  border: none !important;
  margin-top: 4px;
  background-color: #fff;
  img {
    float:left;
    margin-top: 0;
    margin-right: -17px;
    margin-left: 24px;
    width: 24px;
  }
}

.dropdown-toggle {
  &:after {
    position: absolute;
    border: none;
    background-image: url(../img/arrow_lang.svg);
    content: '';
    padding: 0 0 0 5px;
    top: 0;
    right: -18px;
    width: 10px;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.dropdown-item {
  text-align: center;
  padding: 15px;
}
.maxWidth {
  margin-right: 0px!important;
}

.phone-number.phone-numbers-container {
  margin-left: auto;
}

.phone-number.callus-single,
.phone-number.dropdown {
    display: none;
}

.phone-number {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: center;

  .phone-single-item {
    font-weight: 600;
    font-size: 15px;
    color: #00154d;
    letter-spacing: 0.6px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .area-code {
      display: inline-block;
      padding-right: 8px;
      margin-right: 8px;
      border-right: 1px solid transparentize(#00164D, .8);
    }

    .number {
      display: inline-block;
      color: #00164D;
      font-weight: 600;
      margin-right: 8px;
    }

    .language {
      display: inline-block;
      font-weight: 500;
      color: transparentize(#00164D, .6);
      margin-right: 30px;
    }

    img {
      margin-right: 8px;
    }
  }
}

#logo {
  background: url('../img/oman.svg');
}

.powered-by-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2A3F63;
}

.powered-by {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  border: 1px solid rgba(0, 22, 77, .2);
  border-radius: 6px;
  font-size: 10px;
  color: #696969;
  height: 31px;
  width: fit-content;
  white-space: nowrap;

  img {
    margin-left: 6px;
    height: 16px;
  }

  &.mobile {
    display: none;

    @media (max-width: 767.98px) {
      display: flex;
      margin: 15px auto;
      background: #FFFFFF;
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .payments-icon {
    max-width: 100% !important;
  }
  .textarea-rulez {
    width: 100%!important;
  }
  //.ml-auto {
  //  //  margin: unset!important;
  //  //}

  .dropdown-menu {
    margin-top: 0px !important;
  }
  .slogan-logo {
    max-width: none;
  }
}
@media (max-width: 370px) {
  .text-heading-mobile {
    font-size: 15px!important;
    padding-left: 8px!important;
  }
  .zh-text-small {
    margin-top: 0px!important;
    word-break: unset!important;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .text-heading-mobile {
    text-decoration: none;
    display: inline-block;
    line-height: 28px;
    font-size: 15px;
    color: #00154d !important;
    float: right;
    padding-left: 14px;
    word-break: keep-all;
    max-width: 51px;
    white-space: nowrap;
    a {
      &:visited, &:link {
        color: #00154d !important;
      }
    }
  }
  .slogan-text {
    display: none;
  }

  .nav-item.dropdown .powered-by {
    display: none;
  }

  .navbar .navbar-nav .nav-link {
    img {
      margin: 0;
      width: 16px;
      height: 16px;
      margin-right: 0;
    }

    .language {
      display: none;
    }
  }

  .language-switcher {
    margin-right: 0;
    flex-shrink: 0;
  }

  .phone-number.callus-single,
  .phone-number.dropdown {
    margin-left: auto;
    margin-right: 15px;
    display: block;

    .callus-primary {
      display: flex;
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .navbar {
    min-height: auto;
  }

  .navbar-nav {
    flex-direction: unset;
  }

  .navbar-nav .dropdown-menu {
    position: absolute;
    margin-right: -10px;
  }

  .dropdown-menu {
    left: unset;
  }
}

@media (max-width: 450px) {
  .phone-number.dropdown {
    margin-right: 0;
    margin-left: 0;
  }
}