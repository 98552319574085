header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fafbfd;
  z-index: 1000;
}

.wholeLogo {
  display: flex;
  align-items: center;

  img {
    height: 40px;
  }
}

.front-btn {
  width: 100%;
  max-width: 347px;
  height: 70px;
  background: #FFF;
  border-radius: 8px;
  -js-display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 25px 20px;
  transition: background .25s ease;

  &:after {
    content: '';
    display: flex;
    height: 30px;
    width: 30px;
    background-image: url("../img/arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 20px;
    right: 7px;
    transform: scale(1.2);
    filter: brightness(10)
  }

  .text {
    max-width: calc(100% - 40px);
    margin-left: 10px;
    background-position: calc(100% - 20px);
    font-weight: 700;
    font-size: 16px;
    // font-family: ;
  }

  &:nth-of-type(2) {
    margin-top: 35px;
  }
}

.btn-cta,
button.submit-btn.btn-cta,
a.submit-btn.btn-cta {
  background-color: #14ba6d;
  border-radius: 8px;

  &:hover {
    background-color: #2bcd82;
  }
}

.btn-blue,
button.submit-btn.btn-blue,
a.submit-btn.btn-blue {
  background-color: #0a2d5b;

  &:hover {
    background-color: #084693;
  }
}

.page {
  color: #00154D;

  &.page-content {
    p {
      font-size: 16px;
      margin-bottom: 20px;
        &.heading-26{
            font-size: 26px;
            font-weight: 700;
        }
    }
    .heading-20{
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
    }
  }

  .page-wrapper {
    background: white;
    margin-top: 35px;
    margin-bottom: 35px;
    padding: 50px 5%;

    &.form-wrapper {
      padding: 0 5% 0;
      margin-top: 0;
    }

    ul {
        list-style-type: disc; 
        }

    .blogItem {
      display: table;
      width: 100%;

      .blogItem__content,
      .blogItem__contentRight {
        display: table-cell;
      }

      .blogItem__contentRight {
        width: 20%;
        font-size: 16px;
        padding-left: 8px;
        vertical-align: top;
      }

      .blogItem__content {
        box-shadow: inset -6px 0px rgba(50, 50, 50, 0.1);
        padding-right: 15px;
      }

      img {
        width: 80%;
        display: block;
        margin: 0px auto;
      }
    }
  }

  .heading {
    font-size: 26px;
    font-weight: 700;
    margin: 0 0 20px;
  }

  .blogPageContent {
    display: table;
    width: 100%;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
  }
}

.btn-ctared,
button.submit-btn.btn-ctared {
  border: 1px solid #D31216;
  background: #8cc14a;
  background: linear-gradient(to bottom, #FA2B18 0%, #D31216 50%, #D31216 100%);
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    background: rgba(140, 193, 74, 0.8);
    background: linear-gradient(to bottom, rgba(157, 211, 89, 0.8) 0%, rgba(114, 182, 36, 0.8) 50%, rgba(87, 166, 3, 0.8) 100%);
  }
}

.payment-label {
	position: relative;
	height: 27px;
	&.paypal {
		margin-right: 15px;
	}
}

.countries-table {
	td {
		vertical-align: top;
  }
  table {
    border-spacing: 5px;
    width: 100%;
  }
}

footer {
	width: 100%;
	position: relative;
	bottom: 0;
	left: 0;
	z-index: 100;
}

.seo-text {
	background: #FFF;
	padding-top: 30px;
	color: #335075;
	font-size: 14px;
	h1 {
		font-size: 20px;
	}
}

.likeH1 {
  font-size: 24px;
  font-weight: 700;
}

[name="google_conversion_frame"] {
  display: none !important;
}

.how-to-apply-video {
  max-width: 100%;
  margin-bottom: 20px;
}

#countriesList {
	margin: 0px;
	padding: 0px;
	column-count: 4;
	list-style-type: none;
    margin-bottom: 20px;
	a {
		color: #335075;
		&:hover {
			text-decoration: underline;
			color: #004085;
		}
	}
}

.holiday-text {
  background: red;
  color: #fff;
  padding: 8px 0;
  text-align: center;
}

.countries-container {
    column-count: 4;
    margin: 12px auto 20px;
    max-width: 940px;
    .countries-item {
      padding: 8px 0;
      width: 100%;
      height: 42px;
      line-height: 18px;
      font-weight: 500;
      color: #00154D;
      display: flex;
      align-items: center;
      .flag {
        margin-right: 10px;
        margin-left: 3px;
      }
    }
    .country-fake {
      padding: 8px 0;
      width: 100%;
      height: 42px;
      display: flex;
    }
    img{
        min-width: 30px;
        height: 20px;
        margin: 0 10px 0 3px;
    }
}
@media (max-width: 1200px) {
  section.main {
    height: 660px;

    .steps .header {
      font-size: 36px;
    }
  }
}
@media (max-width: 991px) {
  .countries-container {
    column-count: 2;
    margin-top: 0;
  }
}
@media (max-width: 340px) {
  section.main {
    height: 832px;
    background-size: 700px auto;
    background: none;
  }
  .main-background {
    display: block;
  }
}
@media (max-width: 800px) {
  section.main {
    height: 1020px;
  }
  .main-background {
    display: block;
    height: 372px;
  }
}
@media (max-width: 767px) {
  section.main {
    background: none;
  }
}
@media (max-width: 500px) {
  .countries-container {
    column-count: 1;
  }
  .countries-container .country-fake {
    display: none;
  }
}

#evisa-detailed-information {
  margin: 40px auto 40px auto;
  width: 100%;
  font-family: "Lato Regular", Sans-Serif;
  h4 {
    text-align: center;
    font-family: "Lato Black", Sans-Serif;
    font-size: 18px;
  }
  table {
    border-collapse: collapse;
    width: 90%;
    margin: 40px auto 0 auto;
    table-layout: fixed;
    tbody {
      display: table-row-group;
    }
    tr {
      display: table-row;
      &:nth-last-child(2n) td:nth-child(3) {
        background-color: #bcc7db;
      }
      &:nth-last-child(odd) td:nth-child(3) {
        background-color: #dce2ec;
      }
      th, td {
        font-size: 14px;
        border: 1px solid #D9D9D9;
        display: table-cell;
      }
      th {
        background-color: #F4F4F4;
        font-weight: normal;
        text-align: center;
        padding: 10px 5px;
        word-wrap: break-word;
        &:nth-child(1) {
          width: 70%;
        }
        &:nth-child(2), &:nth-child(3) {
          width: 15%;
        }
      }
      td {
        .icon-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        word-wrap: break-word;
        img {
          padding-top: 0px!important;
          width: 12px!important;
        }
        &:not(:first-child) {
          text-align: center;
          font-size: 16px
        }
        &:first-child {
          padding: 10px;
        }
        i.fail-icon {
          color: #DA0710;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #evisa-detailed-information {
    h4 {
      font-size: 16px;
      font-weight: 600;
      padding: 0 16px;
    }

    table {
      margin: 20px auto 0 auto;
      tr {
        th {
          font-size: 11px;
          font-weight: 600;
          line-height: 18px;

          &:nth-child(2), &:nth-child(3) {
            width: 40%;
          }
        }

        td {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
  }
}