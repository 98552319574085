.main {
  height: 640px;
  color: #ffffff;
  .buttons {
    font-weight: 700;
    margin-top: 83px;
    font-size: 14px;
  }

  .title {
    font-size: 48px;
    font-family: "FiraSans", Fallback, sans-serif;
    font-weight: 600;
    padding-top: 50px;

    h1 {
      display: inline;
      font-size: inherit;
    }
  }

  .notice {
    font-family: "Lato", Fallback, sans-serif;
    font-size: 14px;
    padding-top: 20px;
    line-height: 18px;
    font-weight: 500;
  }

  .steps {
    box-shadow: 0px 10px 9px 0px rgba(0,0,0,0.23);
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    background-color: rgba(18, 51, 94, 0.83);

    .header {
      padding: 30px 15px 30px 40px;
      color: #fafbfd;
      font-size: 44px;
      font-weight: 300;
      width: 100%;
      line-height: 52.5px;
      threeeasysteps {
        font-weight: 700;
      }
      strong {
        font-weight: 400;
      }
      p {
        margin: 0px;
        padding-top: 20px;
        font-weight: 300;
        font-size: 14px;
        line-height: 16.5px;
      }
    }
  }
}
.header-steps .container {
  overflow: visible;
  padding-right: 0;
  padding-left: 0;
}
.steps-bottom{
  padding: 0px 15px 24px 15px;
  background-color: #fff;
  margin: 0px;
  .step {
    display: flex;
    color: #000000;
    font-weight: 400;
    margin-top: 34px;
    padding-bottom: 10px;

    a {
      display: flex;
    }

    .no {
      color: #a60417;
      font-size: 72px;
      display: inline-block;
      margin-right: 10px;
      line-height: 46px;
      font-weight: 300;
    }

    .step-data {
      display: flex;
      flex-direction: column;
      width: 100%;

      .step-title {
        display: flex;
        color: #00164d;
        font-size: 18px;
        font-family: "Lato", Fallback, sans-serif;
        font-weight: 700;
        letter-spacing: -0.5px;
        height: 40px;
        line-height: 18px;
      }

      .step-desc {
        display: flex;
        flex-direction: column;
        color: #525766;
        max-width: 380px;
        font-size: 14px;
        width: 100%;
        line-height: 17px;
        font-family: "Lato", Fallback, sans-serif;

        .payments {
          padding: 10px 0px 0 0px;
          display: flex;
          flex-wrap: wrap;
          img.payment-label-cn {
            height: 30px;
          }
        }
      }
    }
  }
}
.government-information-mobile {
  display: none;
}




// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .main {
    .steps {
      height: auto;
      .steps-bottom {
        padding: 0px;
      }
    }
  }
  .steps-bottom {
    min-height: 185px;
    padding: 10px 20px 10px 0px;
    .step-data {
      .step-desc {
        font-size: 12px;
        max-width: 65%;
        line-height: 18px;

        .payments img {
          max-height: 45px;
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .main {
    height: 530px;
    .buttons {
      margin-top: 60px;
    }
    .title {
      font-size: 40px;
    }

    .steps {
      margin-top: 130px;
      .header {
        padding: 15px 15px 15px 30px;
        line-height: 52.5px;
        p {
          padding-top: 15px;
          font-size: 9px;
          line-height: 13.5px;
        }
      }
    }
  }
  .steps-bottom {
    .step {
      .no {
        font-size: 45px;
        top: 24px;
        line-height: 34px;
      }
      .step-data {
        .step-title {
          font-size: 14px;
        }
        .step-desc {
          .payments {
            margin: 0px -10px;
          }
        }
      }
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .main {
    background-image: url("../img/background_m.jpg");
    .container {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .title {
      font-size:30px !important;
      padding-left: 20px;
      min-height: 150px;
    }

    .buttons {
      padding: 0px 0 0 12px;
    }

    .notice {
      display: none;
    }

    .steps {
      margin-top: 90px !important;
      padding: 0 0 0 0 !important;
      box-shadow: none;
    }
  }
  .header-steps .container {
    padding: 0 !important;
  }
  .steps-bottom {
    height: auto !important;
    padding: 0 !important;
    .header {
      max-width: 70%;
      font-size: 35px;
      padding: 25px 30px 0 30px;
      box-shadow: 2px 3.46px 9px 0px rgba(0,0,0,.23);
      height: auto;
      p {
        display: none;
      }
    }
.step:nth-child(2) {
  height: auto;
}
    .step {
      box-shadow: 2px 3.46px 9px 0px rgba(0,0,0,.23);

      @media(max-width: 575px) {
        &:last-child {
          box-shadow: none;
        }
      }

      margin: 0 1px !important;
      padding-top: 42px;
      height: 150px;
      .no {
        font-size: 70px !important;
        line-height: 48px;
        padding-left: 10px;
      }

      .step-data {
        .step-title {
          font-size: 18px !important;
        }
      }
    }
  }
}

@media (max-width: 477.98px) {
  .main {
    .steps {
      .header {
        max-width: 100% !important;
      }
    }
  }
}
@media (max-width: 420.98px) {
  .main {
    .buttons {
      width: calc(100% - 12px);
      margin-top: 80px;
    }
    .steps {
      .header {
        font-size: 29px !important;
        p {
          display: none;
        }
      }
    }
  }
  .government-information-mobile {
    font-family: "Lato", Fallback, sans-serif;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    padding: 10px;
    display: block;
    color: #525766;
    background-color: #EEEEEE;
    margin-top: -20px;
  }
}

// @media (max-width: 350px) {
//   .container {
//     .steps-bottom {
//       .step {
//         height: 140px;
//         padding-top: 36px;

//         &:nth-child(2) {
//           height: 180px;
//         }
//       }
//     }
//   }
// }
@media (max-width: 767px) {
  .main {
    .steps {
      .header {
        font-size: 26px !important;
        padding: 10px 12px 10px 21px;
        line-height: 32px;
      }
      .government-information-mobile {
        padding: 20px 15px 0px 20px;
        font-size: 8px;
      }
    }
  }
  .header-steps .steps-bottom .payments {
    margin: 0px !important;
  }
}
@media (min-width: 421px) {
  section.main {
    height: 1000px;
  }
  .main .main-background {
    height: 480px;
  }
}

@media (min-width: 455px) {
  .main .main-background {
    height: 501px;
  }
}

@media (min-width: 768px) {
  section.main {
    height: 800px;
  }
  .main .main-background {
    height: 800px;
  }
}
