//to make a sprite use https://css.spritegen.com/

//homepage cards
.master, .sofort, .visa
{ display: inline-block; background: url('../img/cards-sprite.png') no-repeat; overflow: hidden; text-indent: -9999px; text-align: left; }

.master { background-position: -0px -0px; width: 64px; height: 39px; }
.sofort { background-position: -0px -39px; width: 64px; height: 39px; }
.visa { background-position: -0px -78px; width: 64px; height: 39px; }

//find out
.albania, .andorra, .argentina, .armenia, .australia,
.austria, .azerbaijan, .belarus, .belgium, .bhutan,
.bolivia, .bosnia-and-herzegovina, .brazil, .brunei, .bulgaria,
.canada, .chile, .china, .colombia, .costa-rica,
.croatia, .cuba, .cyprus, .czech-republic, .denmark,
.ecuador, .el-salvador, .estonia, .finland, .france,
.georgia, .germany, .greece, .guatemala, .honduras,
.hong-kong, .hungary, .iceland, .india, .indonesia,
.iran, .ireland, .italy, .japan, .kazakhstan,
.kyrgyzstan, .laos, .latvia, .lebanon, .liechtenstein,
.lithuania, .luxembourg, .macau, .macedonia, .malaysia,
.maldives, .malta, .mexico, .moldova, .monaco,
.netherlands, .new-zealand, .nicaragua, .norway, .panama,
.paraguay, .peru, .poland, .portugal, .romania,
.russia, .san-marino, .serbia, .seychelles, .singapore,
.slovakia, .slovenia, .south-africa, .south-korea, .spain,
.suriname, .sweden, .switzerland, .taiwan, .tajikistan,
.thailand, .turkey, .turkmenistan, .ukraine, .united-kingdom,
.united-states, .uruguay, .uzbekistan, .vatican-city, .venezuela,
.vietnam
{ display: inline-block; background: url('../img/flag/flags-sprite.png') no-repeat; overflow: hidden; text-indent: -9999px; text-align: left; }

.albania { background-position: -0px -0px; width: 24px; height: 24px; }
.andorra { background-position: -24px -0px; width: 24px; height: 24px; }
.argentina { background-position: -48px -0px; width: 24px; height: 24px; }
.armenia { background-position: -72px -0px; width: 24px; height: 24px; }
.australia { background-position: -96px -0px; width: 24px; height: 24px; }
.austria { background-position: -120px -0px; width: 24px; height: 24px; }
.azerbaijan { background-position: -144px -0px; width: 24px; height: 24px; }
.belarus { background-position: -168px -0px; width: 24px; height: 24px; }
.belgium { background-position: -192px -0px; width: 24px; height: 24px; }
.bhutan { background-position: -0px -24px; width: 24px; height: 24px; }
.bolivia { background-position: -24px -24px; width: 24px; height: 24px; }
.bosnia-and-herzegovina { background-position: -48px -24px; width: 24px; height: 24px; }
.brazil { background-position: -72px -24px; width: 24px; height: 24px; }
.brunei { background-position: -96px -24px; width: 24px; height: 24px; }
.bulgaria { background-position: -120px -24px; width: 24px; height: 24px; }
.canada { background-position: -144px -24px; width: 24px; height: 24px; }
.chile { background-position: -168px -24px; width: 24px; height: 24px; }
.china { background-position: -192px -24px; width: 24px; height: 24px; }
.colombia { background-position: -0px -48px; width: 24px; height: 24px; }
.costa-rica { background-position: -24px -48px; width: 24px; height: 24px; }
.croatia { background-position: -48px -48px; width: 24px; height: 24px; }
.cuba { background-position: -72px -48px; width: 24px; height: 24px; }
.cyprus { background-position: -96px -48px; width: 24px; height: 24px; }
.czech-republic { background-position: -120px -48px; width: 24px; height: 24px; }
.denmark { background-position: -144px -48px; width: 24px; height: 24px; }
.ecuador { background-position: -168px -48px; width: 24px; height: 24px; }
.el-salvador { background-position: -192px -48px; width: 24px; height: 24px; }
.estonia { background-position: -0px -72px; width: 24px; height: 24px; }
.finland { background-position: -24px -72px; width: 24px; height: 24px; }
.france { background-position: -48px -72px; width: 24px; height: 24px; }
.georgia { background-position: -72px -72px; width: 24px; height: 24px; }
.germany { background-position: -96px -72px; width: 24px; height: 24px; }
.greece { background-position: -120px -72px; width: 24px; height: 24px; }
.guatemala { background-position: -144px -72px; width: 24px; height: 24px; }
.honduras { background-position: -168px -72px; width: 24px; height: 24px; }
.hong-kong { background-position: -192px -72px; width: 24px; height: 24px; }
.hungary { background-position: -0px -96px; width: 24px; height: 24px; }
.iceland { background-position: -24px -96px; width: 24px; height: 24px; }
.india { background-position: -48px -96px; width: 24px; height: 24px; }
.indonesia { background-position: -72px -96px; width: 24px; height: 24px; }
.iran { background-position: -96px -96px; width: 24px; height: 24px; }
.ireland { background-position: -120px -96px; width: 24px; height: 24px; }
.italy { background-position: -144px -96px; width: 24px; height: 24px; }
.japan { background-position: -168px -96px; width: 24px; height: 24px; }
.kazakhstan { background-position: -192px -96px; width: 24px; height: 24px; }
.kyrgyzstan { background-position: -0px -120px; width: 24px; height: 24px; }
.laos { background-position: -24px -120px; width: 24px; height: 24px; }
.latvia { background-position: -48px -120px; width: 24px; height: 24px; }
.lebanon { background-position: -72px -120px; width: 24px; height: 24px; }
.liechtenstein { background-position: -96px -120px; width: 24px; height: 24px; }
.lithuania { background-position: -120px -120px; width: 24px; height: 24px; }
.luxembourg { background-position: -144px -120px; width: 24px; height: 24px; }
.macau { background-position: -168px -120px; width: 24px; height: 24px; }
.macedonia { background-position: -192px -120px; width: 24px; height: 24px; }
.malaysia { background-position: -0px -144px; width: 24px; height: 24px; }
.maldives { background-position: -24px -144px; width: 24px; height: 24px; }
.malta { background-position: -48px -144px; width: 24px; height: 24px; }
.mexico { background-position: -72px -144px; width: 24px; height: 24px; }
.moldova { background-position: -96px -144px; width: 24px; height: 24px; }
.monaco { background-position: -120px -144px; width: 24px; height: 24px; }
.netherlands { background-position: -144px -144px; width: 24px; height: 24px; }
.new-zealand { background-position: -168px -144px; width: 24px; height: 24px; }
.nicaragua { background-position: -192px -144px; width: 24px; height: 24px; }
.norway { background-position: -0px -168px; width: 24px; height: 24px; }
.panama { background-position: -24px -168px; width: 24px; height: 24px; }
.paraguay { background-position: -48px -168px; width: 24px; height: 24px; }
.peru { background-position: -72px -168px; width: 24px; height: 24px; }
.poland { background-position: -96px -168px; width: 24px; height: 24px; }
.portugal { background-position: -120px -168px; width: 24px; height: 24px; }
.romania { background-position: -144px -168px; width: 24px; height: 24px; }
.russia { background-position: -168px -168px; width: 24px; height: 24px; }
.san-marino { background-position: -192px -168px; width: 24px; height: 24px; }
.serbia { background-position: -0px -192px; width: 24px; height: 24px; }
.seychelles { background-position: -24px -192px; width: 24px; height: 24px; }
.singapore { background-position: -48px -192px; width: 24px; height: 24px; }
.slovakia { background-position: -72px -192px; width: 24px; height: 24px; }
.slovenia { background-position: -96px -192px; width: 24px; height: 24px; }
.south-africa { background-position: -120px -192px; width: 24px; height: 24px; }
.south-korea { background-position: -144px -192px; width: 24px; height: 24px; }
.spain { background-position: -168px -192px; width: 24px; height: 24px; }
.suriname { background-position: -192px -192px; width: 24px; height: 24px; }
.sweden { background-position: -0px -216px; width: 24px; height: 24px; }
.switzerland { background-position: -24px -216px; width: 24px; height: 24px; }
.taiwan { background-position: -48px -216px; width: 24px; height: 24px; }
.tajikistan { background-position: -72px -216px; width: 24px; height: 24px; }
.thailand { background-position: -96px -216px; width: 24px; height: 24px; }
.turkey { background-position: -120px -216px; width: 24px; height: 24px; }
.turkmenistan { background-position: -144px -216px; width: 24px; height: 24px; }
.ukraine { background-position: -168px -216px; width: 24px; height: 24px; }
.united-kingdom { background-position: -192px -216px; width: 24px; height: 24px; }
.united-states { background-position: -0px -240px; width: 24px; height: 24px; }
.uruguay { background-position: -24px -240px; width: 24px; height: 24px; }
.uzbekistan { background-position: -48px -240px; width: 24px; height: 24px; }
.vatican-city { background-position: -72px -240px; width: 24px; height: 24px; }
.venezuela { background-position: -96px -240px; width: 24px; height: 24px; }
.vietnam { background-position: -120px -240px; width: 24px; height: 24px; }




.flag {
    background-image: url('/assets/img/flags-sprite.png');
    background-repeat: no-repeat;
    display: block;
}

.flag-andorra {
    width: 30px;
    height: 20px;
    background-position: -8px -8px;
}

.flag-australia {
    width: 30px;
    height: 20px;
    background-position: -54px -8px;
}

.flag-austria {
    width: 30px;
    height: 20px;
    background-position: -100px -8px;
}

.flag-bahamas {
    width: 30px;
    height: 20px;
    background-position: -146px -8px;
}

.flag-barbados {
    width: 30px;
    height: 20px;
    background-position: -192px -8px;
}

.flag-belgium {
    width: 30px;
    height: 20px;
    background-position: -238px -8px;
}

.flag-brazil {
    width: 30px;
    height: 20px;
    background-position: -8px -44px;
}

.flag-brunei {
    width: 30px;
    height: 20px;
    background-position: -54px -44px;
}

.flag-bulgaria {
    width: 30px;
    height: 20px;
    background-position: -100px -44px;
}

.flag-chile {
    width: 30px;
    height: 20px;
    background-position: -146px -44px;
}

.flag-croatia {
    width: 30px;
    height: 20px;
    background-position: -192px -44px;
}

.flag-cyprus {
    width: 30px;
    height: 20px;
    background-position: -238px -44px;
}

.flag-czech-republic {
    width: 30px;
    height: 20px;
    background-position: -8px -80px;
}

.flag-denmark {
    width: 30px;
    height: 20px;
    background-position: -54px -80px;
}

.flag-estonia {
    width: 30px;
    height: 20px;
    background-position: -100px -80px;
}

.flag-finland {
    width: 30px;
    height: 20px;
    background-position: -146px -80px;
}

.flag-france {
    width: 30px;
    height: 20px;
    background-position: -192px -80px;
}

.flag-germany {
    width: 30px;
    height: 20px;
    background-position: -238px -80px;
}

.flag-greece {
    width: 30px;
    height: 20px;
    background-position: -8px -116px;
}

.flag-hong-kong {
    width: 30px;
    height: 20px;
    background-position: -54px -116px;
}

.flag-hungary {
    width: 30px;
    height: 20px;
    background-position: -100px -116px;
}

.flag-iceland {
    width: 30px;
    height: 20px;
    background-position: -146px -116px;
}

.flag-ireland {
    width: 30px;
    height: 20px;
    background-position: -192px -116px;
}

.flag-israel {
    width: 30px;
    height: 20px;
    background-position: -238px -116px;
}

.flag-italy {
    width: 30px;
    height: 20px;
    background-position: -8px -152px;
}

.flag-japan {
    width: 30px;
    height: 20px;
    background-position: -54px -152px;
}

.flag-korea-south {
    width: 30px;
    height: 20px;
    background-position: -100px -152px;
}

.flag-latvia {
    width: 30px;
    height: 20px;
    background-position: -146px -152px;
}

.flag-liechtenstein {
    width: 30px;
    height: 20px;
    background-position: -192px -152px;
}

.flag-lithuania {
    width: 30px;
    height: 20px;
    background-position: -238px -152px;
}

.flag-luxembourg {
    width: 30px;
    height: 20px;
    background-position: -8px -188px;
}

.flag-malta {
    width: 30px;
    height: 20px;
    background-position: -54px -188px;
}

.flag-mexico {
    width: 30px;
    height: 20px;
    background-position: -100px -188px;
}

.flag-monaco {
    width: 30px;
    height: 20px;
    background-position: -146px -188px;
}

.flag-netherlands {
    width: 30px;
    height: 20px;
    background-position: -192px -188px;
}

.flag-new-zealand {
    width: 30px;
    height: 20px;
    background-position: -238px -188px;
}

.flag-norway {
    width: 30px;
    height: 20px;
    background-position: -8px -224px;
}

.flag-papua-new-guinea {
    width: 30px;
    height: 20px;
    background-position: -54px -224px;
}

.flag-poland {
    width: 30px;
    height: 20px;
    background-position: -100px -224px;
}

.flag-portugal {
    width: 30px;
    height: 20px;
    background-position: -146px -224px;
}

.flag-romania {
    width: 30px;
    height: 20px;
    background-position: -192px -224px;
}

.flag-samoa {
    width: 30px;
    height: 20px;
    background-position: -238px -224px;
}

.flag-san-marino {
    width: 30px;
    height: 20px;
    background-position: -8px -260px;
}

.flag-singapore {
    width: 30px;
    height: 20px;
    background-position: -54px -260px;
}

.flag-slovakia {
    width: 30px;
    height: 20px;
    background-position: -100px -260px;
}

.flag-slovenia {
    width: 30px;
    height: 20px;
    background-position: -146px -260px;
}

.flag-solomon-islands {
    width: 30px;
    height: 20px;
    background-position: -192px -260px;
}

.flag-spain {
    width: 30px;
    height: 20px;
    background-position: -238px -260px;
}

.flag-sweden {
    width: 30px;
    height: 20px;
    background-position: -284px -8px;
}

.flag-switzerland {
    width: 30px;
    height: 20px;
    background-position: -284px -44px;
}

.flag-taiwan {
    width: 30px;
    height: 20px;
    background-position: -284px -80px;
}

.flag-united-arab-emirates {
    width: 30px;
    height: 20px;
    background-position: -284px -116px;
}

.flag-united-kingdom {
    width: 30px;
    height: 20px;
    background-position: -284px -152px;
}

.flag-vatican-city {
    width: 30px;
    height: 20px;
    background-position: -284px -188px;
}



.payment {
    background-image: url('/assets/img/payment-sprite.png');
    background-repeat: no-repeat;
    display: block;
}

.payment-alipay {
    width: 88px;
    height: 30px;
    background-position: 0 0;
}

.payment-american-express {
    width: 30px;
    height: 30px;
    background-position: -88px 0;
}

.payment-diners {
    width: 37px;
    height: 30px;
    background-position: -118px 0;
}

.payment-discover {
    width: 41px;
    height: 30px;
    background-position: -155px 0;
}

.payment-jbc {
    width: 36px;
    height: 30px;
    background-position: -196px 0;
}

.payment-klarna {
    width: 40px;
    height: 30px;
    background-position: -232px 0;
}

.payment-mastercard {
    width: 36px;
    height: 30px;
    background-position: -272px 0;
}

.payment-paypal {
    width: 80px;
    height: 30px;
    background-position: -308px 0;
}

.payment-sofort {
    width: 76px;
    height: 30px;
    background-position: -388px 0;
}

.payment-unionpay {
    width: 42px;
    height: 30px;
    background-position: -464px 0;
}

.payment-visa {
    width: 42px;
    height: 30px;
    background-position: -506px 0;
}

.payment-webmoney {
    width: 93px;
    height: 30px;
    background-position: -548px 0;
}

.payment-yandexm {
    width: 63px;
    height: 30px;
    background-position: -641px 0;
}
