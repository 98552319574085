@charset "UTF-8";


// @font-face {
//   font-family: "Lato";
//   font-weight: 300;
//   font-display: swap;
//   src: url("../fonts/Lato-Light.woff2") format("woff2")
//        url("../fonts/Lato-Light.woff") format("woff");
// }

@font-face {
  font-family: "Lato";
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Lato-Regular.woff2") format("woff2"),
       url("../fonts/Lato-Regular.woff") format("woff");
}

@font-face {
  font-family: "Lato";
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Lato-Bold.woff2") format("woff2"),
  url("../fonts/Lato-Bold.woff") format("woff");
}

// @font-face {
//   font-family: "Lato";
//   font-weight: 900;
//   font-display: swap;
//   src: url("../fonts/Lato-Black.woff2") format("woff2"),
//        url("../fonts/Lato-Black.woff") format("woff");
// }





/* fira-sans-300 - latin-ext_latin */
@font-face {
  font-family: 'FiraSans';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/fira-sans-v10-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/fira-sans-v10-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/fira-sans-v10-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/fira-sans-v10-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/fira-sans-v10-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/fira-sans-v10-latin-ext_latin-300.svg#FiraSans') format('svg'); /* Legacy iOS */
}

/* fira-sans-regular - latin-ext_latin */
 @font-face {
   font-family: 'FiraSans';
   font-style: normal;
   font-weight: 400;
   src: url('/assets/fonts/fira-sans-v10-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
   src: local(''),
        url('/assets/fonts/fira-sans-v10-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('/assets/fonts/fira-sans-v10-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('/assets/fonts/fira-sans-v10-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
        url('/assets/fonts/fira-sans-v10-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('/assets/fonts/fira-sans-v10-latin-ext_latin-regular.svg#FiraSans') format('svg'); /* Legacy iOS */
 }

/* fira-sans-500 - latin-ext_latin */
@font-face {
  font-family: 'FiraSans';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/fira-sans-v10-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/fira-sans-v10-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/fira-sans-v10-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/fira-sans-v10-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/fira-sans-v10-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/fira-sans-v10-latin-ext_latin-500.svg#FiraSans') format('svg'); /* Legacy iOS */
}

/* fira-sans-600 - latin-ext_latin */
// @font-face {
//   font-family: 'FiraSans';
//   font-style: normal;
//   font-weight: 600;
//   src: url('/assets/fonts/fira-sans-v10-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
//   src: local(''),
//        url('/assets/fonts/fira-sans-v10-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//        url('/assets/fonts/fira-sans-v10-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
//        url('/assets/fonts/fira-sans-v10-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
//        url('/assets/fonts/fira-sans-v10-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
//        url('/assets/fonts/fira-sans-v10-latin-ext_latin-600.svg#FiraSans') format('svg'); /* Legacy iOS */
// }

/* fira-sans-700 - latin-ext_latin */
@font-face {
  font-family: 'FiraSans';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/fira-sans-v10-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/fira-sans-v10-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/fira-sans-v10-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/fira-sans-v10-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/fira-sans-v10-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/fira-sans-v10-latin-ext_latin-700.svg#FiraSans') format('svg'); /* Legacy iOS */
}