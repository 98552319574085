.check-status {
  min-height: 300px;
  // text-align: center;

  h1 {
    margin-top: 20px;
    font-size: 1.75em;
  }

  button {
    width: 300px;
    height: 80px;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 22px;
    margin-top: 50px;
    margin-bottom: 30px;
    font-weight: 500;
    border: none;
  }
}
.form-check-status, .text--center{
  text-align: center;
}